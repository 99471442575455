/* Gallery  */
#gallery {
  display: grid;
  height: calc(100vh - 10px);
  grid-template: repeat(6, 1fr) / repeat(6, 1fr);
  grid-gap: 0.5em;
}

.main__gallery {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.4s transform cubic-bezier(0.155, 1.105, 0.5, 2), 0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.main__gallery:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  z-index: 1 !important;
}

@media (max-width: 800px) {
  #gallery {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }
  #gallery > div {
    width: 60%;
    margin: 1%;
  }
}
@media (max-width: 800px) and (max-width: 350px) {
  #gallery > div {
    width: 98%;
  }
}
#gallery > div:nth-child(6n + 1) {
  grid-column: span 2;
  grid-row: span 2;
}
#gallery > div:nth-child(2) {
  grid-column: span 3;
  grid-row: span 3;
}
#gallery > div:nth-child(4) {
  grid-column: span 1;
  grid-row: span 2;
}
#gallery > div > a {
  opacity: 0;
  position: absolute;
  color: #000;
  background-color: #000;
  font: bold 4em "Helvetica";
  text-shadow: 0 -1px 5px #fff, -1px 0px 5px #fff, 0 1px 5px #fff,
    1px 0px 5px #fff;
  padding: 2rem;
  mix-blend-mode: difference;
  width: 100%;
  height: 100%;
  transition: all ease 1s;
}
#gallery > div > img {
  width: 100%;
  min-height: 100%;
  transition: all ease 1s;
}
#gallery > div:hover img {
  filter: blur(4px);
}
#gallery > div:hover a {
  opacity: 1;
}
#gallery > div {
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
}
#gallery div,
#gallery a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
[id^="lightbox-"] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  opacity: 0;
  transition: opacity 450ms ease-in-out;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2 !important;
}
[id^="lightbox-"]:target {
  opacity: 1;
  pointer-events: inherit;
  z-index: 2 !important;
}
[id^="lightbox-"]:target img {
  filter: blur(0);
  z-index: 2 !important;
}
[id^="lightbox-"] .content {
  max-width: 90%;
  position: relative;
  color: #fff;
  z-index: 2 !important;
}
[id^="lightbox-"] .content:hover > a.close {
  opacity: 1;
  transform: scale(1, 1);
  z-index: 2 !important;
}
[id^="lightbox-"] .content:hover > .title {
  opacity: 1;
  transform: translateY(-3px);
  z-index: 2 !important;
}
[id^="lightbox-"] .content:hover > .title::after {
  opacity: 1;
  z-index: 2 !important;
}
[id^="lightbox-"] .content > * {
  transition: all 450ms ease-in-out;
  z-index: 2 !important;
}
[id^="lightbox-"] .title {
  display: block;
  margin: 0;
  padding: 1em;
  position: absolute;
  bottom: 0;
  width: 2%;
  transform: translateY(50%);
  font-size: 1.5em;
  opacity: 0;
  z-index: 2 !important;
}
[id^="lightbox-"] .title::after {
  content: " ";
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  height: 2%;
  width: 100%;
  position: absolute;
  transition: all 350ms ease-in-out 250ms;
  opacity: 0;
  transform-origin: bottom;
  mix-blend-mode: soft-light;
  z-index: 2 !important;
}
[id^="lightbox-"] img {
  max-height: 90vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
  filter: blur(50px);
}
[id^="lightbox-"] a.close {
  width: 2em;
  height: 2em;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0, 0);
  opacity: 0;
  transform-origin: right top;
  text-decoration: none;
  color: #fff;
}
[id^="lightbox-"] a.close::after {
  content: "X";
}

.gallery__main__image {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  position: relative;
}

.gallery__main__image::before {
  content: "";
  background-image: url("https://res.cloudinary.com/mechi-pharma123/image/upload/v1654322786/Faculty%20Members/Optimized-IMG_1519_farena.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);

  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .gallery__main__image {
    min-height: 60vh;
  }
}

.gallery__main__icon {
  width: 50px !important;
  height: 50px !important;
  color: white;
}

.gallery__main__bba {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ec008d;
  position: relative;
}
