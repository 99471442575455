.modal__close__main {
  cursor: pointer !important;
  width: 30px !important;
  height: 30px !important;
  z-index: 1000000 !important;
}
.modal__close__main__div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  z-index: 1000000;
}

.modal__close__main:hover {
  animation: slidein 1s ease-in-out;
  color: red;
  z-index: 1000000 !important;
}

@keyframes slidein {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
