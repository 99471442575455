/* .photobody{
  height: 600px !important;
} */
.image-title {
  position: absolute;
  top: 100px !important;
  background-color: #073e7f;
  width: 30vw;
  height: 22vh;
  opacity: 0.9;
  padding: 10px;

  box-shadow: 10px 10px #f7f7f7;
  margin-bottom: 400px;
  font-family: "Jost", sans-serif !important;
}
.Title {
  font-size: 2.5vw;
  font-weight: 600;
  text-align: left;
}
.pDescription {
  font-size: 17px !important;
}

@media screen and (max-width: 750px) {
  .image-title {
    position: absolute;
    top: 50px !important;
    background-color: #073e7f;
    width: auto;
    height: 16vh;
    opacity: 0.78;
    padding: 20px;
    margin-bottom: 400px;
    font-family: "Jost", sans-serif !important;
  }
  .Title {
    font-size: 5.5vw;
    font-weight: bold;
  }
  .spann {
    font-size: 12px !important;
  }
}

.spann {
  font-size: 14px;
  padding: 0 1vw;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  80% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
.carousel .carousel-item {
  height: 600px;
}

.carousel-item img {
  position: absolute;
  object-fit: contain;
  top: -200px !important;
  left: 0;
  min-height: 550px;
}

.artigo_nome {
  display: inline-block;
  background-color: #073e7f;
  max-width: 350px;
  text-align: justify;
  padding: 15px;
  word-break: normal;
}
@media screen and (max-width: 750px) {
  .carousel-item img {
    top: 0px !important;
    min-height: auto;
  }
  .carousel .carousel-item {
    height: 250px;
  }
}
