.noti__main__div {
  width: 300px;
  height: 500px;
  position: absolute;
  top: 80px;
  left: 1000px;
  background-color: white;
  border-radius: 14px;
  box-shadow: 1px 1px 10px #837d7d, -1px -1px 7px #ffffff;
  color: rgb(231, 224, 224);
}
