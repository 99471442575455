@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;400&family=EB+Garamond:wght@400;500&family=Jost:wght@300;400;500;800&display=swap");

* {
  font-family: "Jost", sans-serif !important;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
