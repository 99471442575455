.navbar {
  height: 80px !important;
  border-bottom: 2px solid lightgray;
  z-index: 50 !important;
  background-color: white !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: sticky !important;
  top: 0 !important;
}

.nav-item > li {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}

.nav-link {
  font-weight: 400;
  font-size: 16px;
  font-family: "Jost", sans-serif !important;
}
.active:hover {
  border-bottom: none;
}
.nav-item :hover {
  border-bottom: 4px solid #d90081;
  border-bottom-width: 3px;
  transition: 0.051s 0.1s;
  color: #682285 !important;
}

.nav-item .active:hover {
  border-bottom: 4px solid #d90081;
  border-bottom-width: 3px;
}

.navbar-collapse {
  width: 100% !important;
  background-color: rgb(255, 255, 255);
  padding: 5px;
}

.navbar-collapse li {
  font-size: 18px !important;
  margin: 0px 5px !important;
  font-weight: 400;
}
.navbar-collapse li:hover {
  font-weight: 600;
  transition-duration: 0.1s;
}
.navbar-nav {
  margin-left: auto !important;
  margin-right: 5vw;
}

.college__logo__main {
  width: 420px;
  height: 35px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .college__logo__main {
    width: 250px;
    height: 30px;
  }
  .search {
    display: none;
  }
  .menu {
    display: none;
  }
}

.dropdown-menu {
  border-top: 1px solid lightgray !important;
  font-size: 12px;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}
.dropdown-item {
  font-size: 15px;
  border: none !important;
  font-family: "Jost", sans-serif !important;
}

.dropdown-item:hover {
  font-size: 15px;
  border: none !important;
  background-color: white;
}
.search {
  margin-top: 0px;
  border: none !important;
  margin-left: 50px;
}
.menu {
  margin-top: -10px;
  border-bottom: none !important;
}

.secondary__drop > li {
  border: 4px solid transparent !important;
}
.secondary__drop > li:hover > a {
  background-color: lightgray;
}

.inactive {
  border-bottom: 2px solid transparent;
}

.activee {
  border-bottom: 2px solid #d90081;
}

.Active__Badge {
  color: red !important;
}
