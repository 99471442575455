.contact__input__main {
  border: 2px solid lightgray !important;
}

.contact__input__main:focus {
  /* border: #d90081 !important; */
  border: 2px solid #d90081 !important;
}

.contact__us__main__button {
  border: 2px solid #d90081 !important;
  background-color: transparent;
  color: black;
}

.contact__us__main__button:hover {
  background-color: #d90081 !important;
  color: white;
  border: none;
}

.details__contact__us {
  width: 100%;
  height: 150px;
  /* border: 1px solid black; */
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 10px 10px 20px #ababab, -10px -10px 20px #ffffff;
  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .details__contact__us {
    width: 100%;
    height: 300px;
  }
}

.details__address__main span {
  font-size: 20px;
  margin-left: 10px;
}

.details__address__main__icon {
  width: 25px !important;
  height: 25px !important;
  margin-top: -8px;
  color: #ec008d !important;
}
